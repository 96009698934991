import React, { useCallback, useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { MixedTags } from "../Tagify/react.tagify";
import "../Tagify/tagify.css";
import "../Tagify/styles.css";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  textFieldMessage: {
    minHeight: "140px",
  },
  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Required"),
});

const QuickAnswersModal = ({
  open,
  onClose,
  quickAnswerId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    shortcut: "",
    message: "",
  };

  const [quickAnswer, setQuickAnswer] = useState(initialState);
  const [quickAnswerMessage, setQuickAnswerMessage] = useState("");

  // const [expanded, setExpanded] = useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!quickAnswerId) return;

      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        if (isMounted.current) {
          setQuickAnswer(data);
          setQuickAnswerMessage(data.message);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchQuickAnswer();
  }, [quickAnswerId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setQuickAnswer(initialState);
  };

  const handleSaveQuickAnswer = async (values) => {

    try {
      values.message = quickAnswerMessage;
      if (quickAnswerId) {
        await api.put(`/quickAnswers/${quickAnswerId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/quickAnswers", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("quickAnswersModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = useCallback(e => {
    setQuickAnswerMessage(e.detail.value);
  }, [])

  
  // Tagify settings object
  const settings = {
    pattern: /@/,  // <- must define "patten" in mixed mode
    dropdown: {
      enabled: 0,
      position: "text"
    },
    whitelist: [
      // Contact vars      
      { value: i18n.t("variables.list.contact_firstName"), title: "##contact_firstName##" },
      { value: i18n.t("variables.list.contact_fullName"), title: "##contact_fullName##" },
      { value: i18n.t("variables.list.contact_email"), title: "##contact_email##" },
      { value: i18n.t("variables.list.contact_birthday"), title: "##contact_birthday##" },
			{ value: i18n.t("variables.list.contact_cod"), title: "##contact_cod##" },
      { value: i18n.t("variables.list.contact_phone"), title: "##contact_phone##" },

      //  // Ticket vars
      { value: i18n.t("variables.list.ticket_id"), title: "##ticket_id##" },

      //  // User vars: use if exists
      { value: i18n.t("variables.list.user_name"), title: "##user_name##" },
      { value: i18n.t("variables.list.user_email"), title: "##user_email##" },

      //  // Misc
      { value: i18n.t("variables.list.greeting"), title: "##greeting##" },
      { value: i18n.t("variables.list.protocol"), title: "##protocol##" },
      { value: i18n.t("variables.list.date_us"), title: "##date_us##" },
      { value: i18n.t("variables.list.date_br"), title: "##date_br##" },

      { value: i18n.t("variables.list.break_message")+1, title: "##break_message##" },
      { value: i18n.t("variables.list.break_message")+2, title: "##break_message##" },
      { value: i18n.t("variables.list.break_message")+3, title: "##break_message##" },
      { value: i18n.t("variables.list.break_message")+4, title: "##break_message##" },
      { value: i18n.t("variables.list.break_message")+5, title: "##break_message##" },

    ]
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {quickAnswerId
            ? `${i18n.t("quickAnswersModal.title.edit")}`
            : `${i18n.t("quickAnswersModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          validationSchema={QuickAnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQuickAnswer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("quickAnswersModal.form.shortcut")}
                    name="shortcut"
                    autoFocus
                    error={touched.shortcut && Boolean(errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>

                <div className={classes.textQuickAnswerContainer}>
                <Typography className={classes.heading}>{i18n.t("quickAnswersModal.form.message")} | {i18n.t("quickAnswersModal.tip")} </Typography>
                  <MixedTags
                    settings={settings}
                    className={classes.textFieldMessage}
                    onChange={onChange}
                    value={`${quickAnswer.message}`}
                    placeholder={i18n.t("quickAnswersModal.tip")}
                  />
                </div>                
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickAnswerId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

    </div>
  );
};

export default QuickAnswersModal;
